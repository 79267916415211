export const searchProfileDefaultInstance = {
  atmosphereAndDesign: 3,
  comfortAndService: 3,
  environmentalAndSocial: 3,
  facilitiesAndActivities: 3,
  foodAndBeverages: 3,
  importanceOfPrice: 3,
  locationAndAccess: 3,
};

export const comfort = {
  initialValue: 'IMPORTANT',
  name: 'comfortAndService',
  placeholder: 'Select comfort & service',
  label: 'Comfort & Service',
  values: [
    { value: 'NOT_IMPORTANT_AT_ALL', label: '1 - not at all important' },
    { value: 'A_LITTLE_IMPORTANT', label: '2 - a little important' },
    { value: 'IMPORTANT', label: '3 - important' },
    { value: 'QUITE_IMPORTANT', label: '4 - quite important' },
    { value: 'VERY_IMPORTANT', label: '5 - very important' },
  ],
};

export const location = {
  initialValue: 'IMPORTANT',
  name: 'locationAndConvenience',
  placeholder: 'Select location & convenience',
  label: 'Location & Convenience',
  values: [
    { value: 'NOT_IMPORTANT_AT_ALL', label: '1 - not at all important' },
    { value: 'A_LITTLE_IMPORTANT', label: '2 - a little important' },
    { value: 'IMPORTANT', label: '3 - important' },
    { value: 'QUITE_IMPORTANT', label: '4 - quite important' },
    { value: 'VERY_IMPORTANT', label: '5 - very important' },
  ],
};

export const travel = {
  initialValue: {
    locationAndConvenience: 'IMPORTANT',
    atmosphereAndDesign: 'IMPORTANT',
    facilitiesAndActivities: 'IMPORTANT',
    foodAndBeverages: 'IMPORTANT',
    environmentalAndSocial: 'IMPORTANT',
  },
  name: 'travel',
  placeholder: 'Select travel style',
  label: 'Travel style',
  values: [
    {
      value: {
        locationAndConvenience: 'IMPORTANT',
        atmosphereAndDesign: 'IMPORTANT',
        facilitiesAndActivities: 'IMPORTANT',
        foodAndBeverages: 'IMPORTANT',
        environmentalAndSocial: 'IMPORTANT',
      },
      label: 'Value focused',
    },
    {
      value: {
        locationAndConvenience: 'QUITE_IMPORTANT',
        atmosphereAndDesign: 'IMPORTANT',
        facilitiesAndActivities: 'A_LITTLE_IMPORTANT',
        foodAndBeverages: 'QUITE_IMPORTANT',
        environmentalAndSocial: 'A_LITTLE_IMPORTANT',
      },
      label: 'Central (tourist)',
    },
    {
      value: {
        locationAndConvenience: 'A_LITTLE_IMPORTANT',
        atmosphereAndDesign: 'VERY_IMPORTANT',
        facilitiesAndActivities: 'A_LITTLE_IMPORTANT',
        foodAndBeverages: 'IMPORTANT',
        environmentalAndSocial: 'IMPORTANT',
      },
      label: 'Atmospheric / Romantic',
    },
    {
      value: {
        locationAndConvenience: 'A_LITTLE_IMPORTANT',
        atmosphereAndDesign: 'A_LITTLE_IMPORTANT',
        facilitiesAndActivities: 'QUITE_IMPORTANT',
        foodAndBeverages: 'QUITE_IMPORTANT',
        environmentalAndSocial: 'IMPORTANT',
      },
      label: 'Family / Full facilities',
    },
    {
      value: {
        locationAndConvenience: 'QUITE_IMPORTANT',
        atmosphereAndDesign: 'A_LITTLE_IMPORTANT',
        facilitiesAndActivities: 'QUITE_IMPORTANT',
        foodAndBeverages: 'IMPORTANT',
        environmentalAndSocial: 'A_LITTLE_IMPORTANT',
      },
      label: 'Business',
    },
  ],
};

export const importance = {
  initialValue: 'IMPORTANT',
  name: 'priceImportance',
  placeholder: 'Select importance of price',
  label: 'Importance of price',
  values: [
    { value: 'NOT_IMPORTANT_AT_ALL', label: '1 - not at all important' },
    { value: 'A_LITTLE_IMPORTANT', label: '2 - a little important' },
    { value: 'IMPORTANT', label: '3 - important' },
    { value: 'QUITE_IMPORTANT', label: '4 - quite important' },
    { value: 'VERY_IMPORTANT', label: '5 - very important' },
  ],
};

export const atmosphere = {
  initialValue: 'IMPORTANT',
  name: 'atmosphereAndDesign',
  placeholder: 'Select atmosphere & design',
  label: 'Atmosphere & Design',
  values: [
    { value: 'NOT_IMPORTANT_AT_ALL', label: '1 - not at all important' },
    { value: 'A_LITTLE_IMPORTANT', label: '2 - a little important' },
    { value: 'IMPORTANT', label: '3 - important' },
    { value: 'QUITE_IMPORTANT', label: '4 - quite important' },
    { value: 'VERY_IMPORTANT', label: '5 - very important' },
  ],
};

export const food = {
  initialValue: 'IMPORTANT',
  name: 'foodAndBeverages',
  placeholder: 'Select food & beverages',
  label: 'Food & Beverages',
  values: [
    { value: 'NOT_IMPORTANT_AT_ALL', label: '1 - not at all important' },
    { value: 'A_LITTLE_IMPORTANT', label: '2 - a little important' },
    { value: 'IMPORTANT', label: '3 - important' },
    { value: 'QUITE_IMPORTANT', label: '4 - quite important' },
    { value: 'VERY_IMPORTANT', label: '5 - very important' },
  ],
};

export const facilities = {
  initialValue: 'IMPORTANT',
  name: 'facilitiesAndActivities',
  placeholder: 'Select facilities & activities',
  label: 'Facilities & Activities',
  values: [
    { value: 'NOT_IMPORTANT_AT_ALL', label: '1 - not at all important' },
    { value: 'A_LITTLE_IMPORTANT', label: '2 - a little important' },
    { value: 'IMPORTANT', label: '3 - important' },
    { value: 'QUITE_IMPORTANT', label: '4 - quite important' },
    { value: 'VERY_IMPORTANT', label: '5 - very important' },
  ],
};

export const property = {
  initialValue: 'IMPORTANT',
  name: 'environmentalAndSocial',
  placeholder: 'Select environmental & social',
  label: 'Environmental & Social',
  values: [
    { value: 'NOT_IMPORTANT_AT_ALL', label: '1 - not at all important' },
    { value: 'A_LITTLE_IMPORTANT', label: '2 - a little important' },
    { value: 'IMPORTANT', label: '3 - important' },
    { value: 'QUITE_IMPORTANT', label: '4 - quite important' },
    { value: 'VERY_IMPORTANT', label: '5 - very important' },
  ],
};

export const confirmPassword = {
  initialValue: '',
  name: 'confirmPassword',
  placeHolder: 'Confirm password here',
};

export const password = {
  initialValue: '',
  name: 'password',
  placeHolder: 'Enter password here',
};

export const newPassword = {
  initialValue: '',
  name: 'newPassword',
  placeHolder: 'Enter new password',
};

export const currentPassword = {
  initialValue: '',
  name: 'password',
  placeHolder: 'Enter current password',
};

export const email = {
  initialValue: '',
  name: 'email',
  placeHolder: 'Enter email here',
};

export const country = {
  initialValue: '',
  name: 'country',
  placeHolder: 'Enter country here',
};

export const weblink = {
  initialValue: '',
  name: 'link',
  placeHolder: 'Enter website link',
};

export const roomsCount = {
  initialValue: '',
  name: 'roomsCount',
  placeHolder: 'Enter number of rooms here',
};

export const price = {
  initialValue: '',
  name: 'price',
  placeHolder: '0',
};

export const currency = {
  initialValue: '',
  name: 'currency',
  placeholder: 'Currency',
  label: 'Currency',
  values: [
    { value: 'USD', label: 'USD' },
    { value: 'UAH', label: 'UAH' },
    { value: 'EUR', label: 'EUR' },
    { value: 'HKD', label: 'HKD' },
    { value: 'SGD', label: 'SGD' },
    { value: 'GBP', label: 'GBP' },
    { value: 'AED', label: 'AED' },
    { value: 'IDR', label: 'IDR' },
    { value: 'TRY', label: 'TRY' },
    { value: 'JPY', label: 'JPY' },
  ],
};

export const howFindUs = {
  initialValue: '',
  name: 'howFindUs',
  placeholder: 'Select or write description',
  values: [
    { label: 'News Article', value: 'News Article' },
    { label: 'Friends', value: 'Friends' },
    {
      label: 'Other property already on Koralgo',
      value: 'Other property already on Koralgo',
    },
  ],
};

export const contactTopic = {
  initialValue: '',
  name: 'topic',
  placeholder: 'Select or type',
  values: [
    { label: ' Edit and cancellation query', value: ' Edit and cancellation query' },
    { label: 'Refund related', value: 'Refund related' },
    {
      label: 'Technical issue',
      value: 'Technical issue',
    },
    {
      label: 'Privacy and Cookies policies',
      value: 'Privacy and Cookies policies',
    },
    {
      label: 'Media interest',
      value: 'Media interest',
    },
    {
      label: 'Rating services',
      value: 'Rating services',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
};

export const message = {
  initialValue: '',
  name: 'message',
  placeholder: 'Your message typed here',
};

export const channelManager = {
  initialValue: '',
  name: 'channelManagersName',
  placeholder: 'Select or write manager',
  values: [
    { label: 'No Channel Manager', value: 'No Channel Manager' },
  ],
};

export const title = {
  initialValue: '',
  name: 'title',
  placeholder: 'Enter title text',
};

export const description = {
  initialValue: '',
  name: 'description',
  placeholder: 'Enter description text',
};

export const transportType = {
  initialValue: '',
  name: 'type',
  placeholder: 'Select transport type',
  values: [
    { label: 'Airport', value: 'AIRPORT' },
    { label: 'Train station', value: 'TRAIN_STATION' },
    { label: 'Underground', value: 'UNDERGROUND' },
    { label: 'Bus station', value: 'BUS_STATION' },
    { label: 'Tramway', value: 'TRAMWAY' },
    { label: 'Ferry', value: 'FERRY' },
    { label: 'Other', value: 'OTHER' },
  ],
};

export const distance = {
  initialValue: '',
  name: 'distance',
  placeholder: 'km',
};

export const locationName = {
  initialValue: '',
  name: 'title',
  placeholder: 'Enter name here',
};

export const locationComment = {
  initialValue: '',
  name: 'description',
  placeholder: 'Enter info / comment here',
};

export const interval = {
  initialValue: '',
  name: 'interval',
  placeholder: '',
  values: [
    { label: '/ h', value: 'per/h' },
    { label: '/ day', value: 'per/day' },
  ],
};

export const priceFacility = {
  initialValue: '',
  name: 'price',
  placeHolder: 'Price here',
};

export const descriptionFacility = {
  initialValue: '',
  name: 'description',
  placeHolder: 'Add comment here',
};

const NAOption = { label: 'Paid', value: 'PAID' };

export const facilityRadioStatus = {
  initialValue: '',
  name: 'status',
  values: [
    { label: 'Free', value: 'FREE' },
    NAOption,
    { label: 'N/A', value: 'NOT_AVAILABLE' },
  ],
};

const NAOptionIndex = facilityRadioStatus.values.indexOf(NAOption);

// Rooms

export const roomType = {
  initialValue: '',
  name: 'title',
  placeholder: 'Enter room type',
};

export const roomDescr = {
  initialValue: '',
  name: 'description',
  placeholder: 'Enter description here',
};

export const roomSurface = {
  initialValue: '',
  name: 'surface',
  placeholder: 'Enter surface here',
};

export const roomDesignedCapacityForAdults = {
  initialValue: '',
  name: 'designedCapacityForAdults',
  placeholder: 'Choose designed capacity',
};

export const roomDesignedCapacityForKids = {
  initialValue: '',
  name: 'designedCapacityForKids',
  placeholder: 'Choose designed capacity',
};

export const roomOptimizedCapacityForAdults = {
  initialValue: '',
  name: 'optimizedCapacityForAdults',
  placeholder: 'Choose optimized capacity',
};

export const roomOptimizedCapacityForKids = {
  initialValue: '',
  name: 'optimizedCapacityForKids',
  placeholder: 'Choose optimized capacity',
};

export const roomMaximumCapacityForAdults = {
  initialValue: '',
  name: 'maximumCapacityForAdults',
  placeholder: 'Choose maximum capacity',
};

export const roomMaximumCapacityForKids = {
  initialValue: '',
  name: 'maximumCapacityForKids',
  placeholder: 'Choose maximum capacity',
};

export const designedCapacitySum = {
  name: 'designedCapacitySum',
};

export const maxCapacitySum = {
  name: 'maxCapacitySum',
};

export const optimizedCapacitySum = {
  name: 'optimizedCapacitySum',
};

export const bedTypes = {
  initialValue: '',
  name: 'title',
  placeholder: 'Choose type of bed',
  values: [
    { label: 'Single', value: 'Single' },
    { label: 'Super single', value: 'Super single' },
    { label: 'Double', value: 'Double' },
    { label: 'Queen', value: 'Queen' },
    { label: 'King', value: 'King' },
    { label: 'Futon', value: 'Futon' },
    { label: 'Others', value: 'Others' },
  ],
};

export const bedWidth = {
  initialValue: '',
  name: 'bedWidth',
  placeholder: '00 cm',
};

export const firstName = {
  initialValue: '',
  name: 'firstName',
  placeholder: 'Enter first name',
};

export const lastName = {
  initialValue: '',
  name: 'lastName',
  placeholder: 'Enter first name',
};

export const nickName = {
  name: 'nickName',
  placeholder: 'Enter nick name',
};

export const titleSelect = {
  name: 'title',
  placeholder: 'Select title',
  options: [
    { label: 'Mr', value: 'Mr.' },
    { label: 'Ms', value: 'Ms.' },
    { label: 'Mrs', value: 'Mrs.' },
  ],
};

export const phone = {
  name: 'phone',
};

export const birthDate = {
  name: 'birthDate',
  placeholder: 'Enter date of Birth',
};

export const passportId = {
  name: 'passportId',
  placeholder: 'Enter passport or ID',
};

export const ADDRESS_LOCATION = {
  locationName: '',
  placeId: '',
  longitude: '',
  latitude: '',
  country: '',
  region: '',
  city: '',
  postalCode: '',
};

export const googleLocation = {
  name: 'location',
  placeholder: 'Enter your address',
};

export const nationality = {
  name: 'nationality',
  label: 'Nationality',
  placeholder: 'Search for your nationality',
};

export const COMPLETE_PROFILE = {
  firstName,
  lastName,
  nickName,
  titleSelect,
  birthDate,
  passportId,
  googleLocation,
  phone,
  nationality,
};

export const ROOM_BED_SETTINGS = {
  bedTypes,
  bedWidth,
};

export const ROOM_GENERAL_INFO = {
  roomType,
  roomDescr,
  roomSurface,
  roomDesignedCapacityForAdults,
  roomDesignedCapacityForKids,
  roomOptimizedCapacityForAdults,
  roomOptimizedCapacityForKids,
  roomMaximumCapacityForAdults,
  roomMaximumCapacityForKids,
  designedCapacitySum,
  maxCapacitySum,
  optimizedCapacitySum,
};

export const EXPRESS_PROPERTY = {
  weblink,
  roomsCount,
  currency,
  price,
  howFindUs,
  channelManager,
  email,
};

export const AUTH = {
  password,
  email,
  firstName,
  lastName,
  country,
};

export const RESET_PWD = {
  confirmPassword,
  password,
};

export const AT_LEAST_ONE_FIELD = {
  error: 'Select at least one option',
};

export const PROFILING = {
  comfort,
  travel,
  importance,
};

export const PROFILING_FULL = {
  comfort,
  location,
  importance,
  property,
  facilities,
  food,
  atmosphere,
};

export const LOCATION_TRANSPORT = {
  transportType,
  distance,
  locationName,
  locationComment,
};

export const KEY_FACILITIES = {
  priceFacility,
  currency,
  interval,
  descriptionFacility,
  facilityRadioStatus,
  NAOptionIndex,
};

export const ADMIN_PASSWORD = {
  confirmPassword,
  newPassword,
  currentPassword,
};

export const CUSTOMER_EMAIL = {
  password,
  email,
};

export const CONTACT_FORM_FIELDS = {
  contactTopic,
  email,
  message,
};
